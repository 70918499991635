import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../utils/api';
import S from './MainPopup.module.css';

import { popuptitle } from '../../assets/images/mainImages';

const MainPopup = () => {
  
  const [showPopup, setShowPopup] = useState(true);
  const [hidePopupToday, setHidePopupToday] = useState(false);
  const [popupData, setPopupData] = useState([]);

  useEffect(() => {
    const fetchPopupData = async () => {
      const popData = await get('/main/popup/list');
      setPopupData(popData);
      console.log( popData );
    };
    fetchPopupData();
  }, []);

  useEffect(() => {
    const isPopupClosedToday = localStorage.getItem('popupClosedToday');
    if (isPopupClosedToday) {
      const now = new Date().getTime();
      if (now > parseInt(isPopupClosedToday)) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }
  }, [showPopup]);

  const handleClosePopup = () => {
    if (hidePopupToday) {
      let expires = new Date();
      expires = expires.setHours(23, 59, 59, 0);
      localStorage.setItem("popupClosedToday", expires);
    }
    setShowPopup(false);
  };

  const handleCheckboxChange = (event) => {
    setHidePopupToday(event.target.checked);
  };

  return (
    <>
      {showPopup && popupData.length > 0 && (
        <div id={S.popWrap}>
          <div className='popup'>
            <div id={S.popTitle}>
              <img src={popuptitle} alt='title'/>
            </div>
            <div id={popupData.length!==4 ? S.popBodyA : S.popBodyB}>
              {popupData && popupData.map((item, index) => (
                <div className={S.popCon} key={index}>
                  <Link to={item.P_LINK} target="_blank"><img className={S.popImg} src={item.P_IMG_PATH} alt=''/></Link>
                </div>
              ))}
            </div>
            <div id={S.popBtnWrap}>
              <label className='cursor'>
                <input
                  id={S.checkInput}
                  type="checkbox"
                  checked={hidePopupToday}
                  onChange={handleCheckboxChange}
                />
                오늘 하루 안보기
              </label>
              <button id={S.closeBtn} onClick={handleClosePopup}>닫기</button>
            </div>
          </div>
        </div>
      )} 
    </>

  );
}

export default MainPopup;
