import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './MainPage.css';
import MainPopup from '../../components/Popup/MainPopup';

import { mainPage1, mainPage2, mainPage3, mainEPRImg, mainList01_Off, mainList01_On, mainList02_Off, mainList02_On, mainList03_Off, mainList03_On } from '../../assets/images/mainImages'
import { prdUseMain, prdUse01, prdUse02, prdUse03, prdUse04, prdUse05, plusBtn } from '../../assets/images/mainImages'
import { btNotice_Off, btNotice_On, btPI_Off, btPI_On, btIntro_Off, btIntro_On, btInfo_Off, btInfo_On } from '../../assets/images/mainImages'
import SideBannerModal from '../../components/Modal/SideBannerModal';
import PopupModal from '../../components/Modal/PopupModal';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Main = () => {

  const [mbResult, setMbResult] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);

  const SERVER_URL = "https://sensetab.co.kr/";
  
  const mbData = async() => {
    try {
      const response = await get('/main/test');
      setMbResult(response);
    } catch(err) {
      console.log(err);
    };
  };

  const mainProductData = async() => {
    try {
      const response = await get('/main/product');
      setMainProduct(response);
    } catch(err) {
      console.log(err);
    };
  };

  useEffect(() => {
    mbData();
    mainProductData();
  }, []);

  const showMainProduct = (prdImgPath, idx) => {
  
    return (
      <div className='mainprdlist' key={prdImgPath}>
        <Link to={`/product/detail/${idx}`}>
          <img src={SERVER_URL + prdImgPath} alt='대표이미지'></img>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div id='main-img'>
        <Swiper
          slidesPerView={1}
          initialSlide={0} // 시작 위치 값
          centeredSlides={true}
          rewind={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
         }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide><div><Link to={'/'}><img src={mainPage1} alt="메인이미지" /></Link></div></SwiperSlide>
          <SwiperSlide><div><Link to={'http://rfemfo.co.kr/Tag/MakeTAG.asp'} target="_blank"><img src={mainPage2} alt="메인이미지2" /></Link></div></SwiperSlide>
          <SwiperSlide><div><Link to={'http://rfemfo.co.kr/Reader/RFID_Reader.asp'} target="_blank"><img src={mainPage3} alt="메인이미지3" /></Link></div></SwiperSlide>
        </Swiper>
      </div>

      <div id='main-container'>
         <MainPopup />
         <SideBannerModal />
        
        <div id='main-reader-div-wrap'>
          <h1 className='text-center'>EPR-RFID 리더기</h1>
          <div id='main-reader-wrap'>
            <div id='reader-main-img'>
              <img src={mainEPRImg} alt='메인리더기' />
            </div>
            <div id='readers'>
              <div className='info-div'>
                <Link to={'/product/list'}>
                  <img className='hover-off' src={mainList01_Off} alt='주파수별 리더기'/>
                  <img className='hover-on' src={mainList01_On} alt='주파수별 리더기'/>
                </Link>
              </div>
              <div className='info-div'>
                <Link to={'/product/list'}>
                  <img className='hover-off' src={mainList02_Off} alt='통신방식별 리더기'/>
                  <img className='hover-on' src={mainList02_On} alt='통신방식별 리더기'/>
                </Link>
              </div>
              <div className='info-div'>
                <Link to={'/product/list'}>
                  <img className='hover-off' src={mainList03_Off} alt='사용 용도별 리더기'/>
                  <img className='hover-on' src={mainList03_On} alt='사용 용도별 리더기'/>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h1 className='text-center'>대표 PRODUCT</h1>

          <div className='main-prd-div'>
              {mainProduct && mainProduct.map((mp) => (
                mp.PRD_IMG_PATH && mp.PRD_IDX ? showMainProduct(mp.PRD_IMG_PATH, mp.PRD_IDX) : null
              ))}
            <div className='main-prd-plus'>
              <Link to={'/product/list'}><img src={plusBtn} alt='더보기버튼'/></Link>
            </div>
          </div>
        </div>

        <div>
          
          <div className='sub-img-div-main'>
            <img src={prdUseMain}  alt='제품활용분야'/>
          </div>
          
          {/* <h1 className='text-center'>제품 활용 분야</h1>

          <div className='sub-img-div'>
            <img src={prdUse01}  alt='제품활용분야'/>
          </div>
          <div className='sub-img-div2'>
            <img src={prdUse03}  alt='제품활용분야'/>
            <img src={prdUse04}  alt='제품활용분야'/>
            <img src={prdUse05}  alt='제품활용분야'/>
          </div>
          <div className='sub-img-div3'>
            <img src={prdUse02}  alt='제품활용분야-소개글'/>
          </div> */}
        </div>

        <div id='info-wrap'>
          <div className='info-div'>
            <Link to={'/notice/list'}>
              <img className='hover-off' src={btNotice_On} alt='공지사항'/>
              <img className='hover-on' src={btNotice_Off} alt='공지사항'/>
            </Link>
          </div>
          <div className='info-div'>
            <Link to={'/inquiry/insert'}>
              <img className='hover-off' src={btPI_On} alt='구매/견적문의'/>
              <img className='hover-on' src={btPI_Off} alt='구매/견적문의'/>
            </Link>
          </div>
          <div className='info-div'>
            <Link to={SERVER_URL + mbResult} target="_blank">
              <img className='hover-off' src={btIntro_On} alt='제품소개서'/>
              <img className='hover-on' src={btIntro_Off} alt='제품소개서'/>
            </Link>
          </div>
          <div className='info-div'>
            <Link to={'/product/list'}>
              <img className='hover-off' src={btInfo_On} alt='자료실'/>
              <img className='hover-on' src={btInfo_Off} alt='자료실'/>
            </Link>
          </div>

        </div>
      </div>
      
    </>
  );
};

export default Main;